import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Dot = styled.div`
  display: inline-block;
  width: 6px;
  height: 6px;
  background: white;
  margin-right: 4px;
  border-radius: 3px;

  &:last-child {
    margin-right: 0;
  }

  animation: dot-typing 800ms ${(props) => props.number * 200}ms infinite linear;

  @keyframes dot-typing {
    0%,
    25% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-100%);
    }

    75%,
    100% {
      transform: translateY(0);
    }
  }
`;

function LoaderTyping({ style, dotStyle }) {
  return (
    <Wrapper style={style}>
      <Dot style={dotStyle} number={0} />
      <Dot style={dotStyle} number={1} />
      <Dot style={dotStyle} number={2} />
    </Wrapper>
  );
}

LoaderTyping.propTypes = {
  style: PropTypes.object,
  dotStyle: PropTypes.object,
};

export default memo(LoaderTyping);
