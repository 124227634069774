import Head from "next/head";
import Image from "next/image";
import HeroImage from "../public/hero-sales-landingpage-2400x.jpg";
import SalesImage from "../public/sales-beratung.jpg";
import styled from "styled-components";
import Link from "next/link";
import ContactForm from "../components/ContactForm";
import { useRef } from "react";
import { useRouter } from "next/router";

const HeroElement = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center left;
  }
`;

const SalesImageWrapper = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const SalesPitchArgument = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  & h2 {
    color: #2f9f2f;
    margin-bottom: 15px;
  }

  & p {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.62px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const FooterWrapper = styled.div`
  h4 {
    margin-bottom: 15px;
  }
  ul li {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.62px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & a:hover {
    text-decoration: underline;
  }
`;

const LiElem = (props) => {
  return (
    <li className="flex items-center" {...props}>
      <span className="mr-4 h-[25px] grow-0 shrink-0">
        <Image
          src="/IconCheckGreenFull.svg"
          alt="Bulletpoint"
          width={25}
          height={25}
          style={{
            maxWidth: "100%",
            height: "auto"
          }} />
      </span>
      <span>{props.children}</span>
    </li>
  );
};

export default function Home() {
  const formRef = useRef();

  const router = useRouter();
  const { city, restaurantName, name, street, houseNumber, zip } = router.query;

  const scrollToForm = () => {
    const element = formRef.current;
    element.focus();
  };

  const WhyElement = (props) => {
    return (
      <div {...props}>
        <h2 className="text-[21px] tracking-[0.93px] mb-[15px]">
          Warum <span className="text-fa-green">FoodAlley Partner</span> werden?
        </h2>
        <ul className="bulletPoints">
          <LiElem>
            Faires Provisionsmodell von <strong>7,5%</strong>
          </LiElem>
          <LiElem>
            <strong>Keine</strong> monatlichen Fixkosten
          </LiElem>
          <LiElem>
            <strong>Keine</strong> Einrichtungskosten
          </LiElem>
          <LiElem>
            <strong>Kostenfreie</strong> Werbung für Sie
          </LiElem>
          <LiElem>
            Selbständige Anpassungen und persönlicher Kundensupport
          </LiElem>
          <LiElem>
            Kostengünstige und moderne Hardware, <strong>einmalig 169 €</strong>{" "}
            + USt.
          </LiElem>
        </ul>
        <div className="lg:hidden mt-[20px] flex justify-center">
          <button
            type="button"
            className="w-full md:w-[50%] md:self-center bg-fa-green text-white h-[44px] rounded-md tracking-widest"
            onClick={scrollToForm}
          >
            Jetzt anfragen
          </button>
        </div>
      </div>
    );
  };

  const CompareLine = (props) => {
    return (
      <>
        <div className="text-fa-green font-bold text-[18px] tracking-[0.8px]">
          {props.left}
        </div>
        <div className="text-[#979797] text-[14px] tracking-[0.62px]">
          {props.middle}
        </div>
        <div className="text-[#979797] text-[16px] tracking-[0.71px]">
          {props.right}
        </div>
      </>
    );
  };

  const RecommendationBox = (props) => {
    return (
      <div className="bg-white rounded-sm p-4 w-full shadow-sm mb-[5px] md:mb-0 last:mb-0 flex flex-col justify-between">
        <div className="font-light text-[14px] tracking-[0.62px] mb-[20px] grow">
          &quot;{props.text}&quot;
        </div>
        <div className="flex align-center">
          <div className="flex items-center justify-center w-[60px] mr-4">
            <Image
              src={props.logoSrc}
              width={props.logoWidth}
              height={props.logoHeight}
              title={props.restaurantName}
              alt={`Logo ${props.restaurantName}`}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-bold text-[14px] tracking-[0.62px] m-0">
              {props.ownerName}
            </p>
            <span className="font-light text-[14px] tracking-[0.62px] m-0">
              Inhaber{" "}
              <a
                href={props.url}
                target="_blank"
                className="text-fa-green"
                rel="noreferrer"
              >
                {props.restaurantName}
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Head>
        <title>Jetzt Partner werden! 🤝 FoodAlley.de 🏆</title>
        <meta
          name="description"
          content="Werden Sie jetzt Partner von FoodAlley.de und sparen Sie sich überhöhte Provisionen. Bringen Sie Ihren Lieferdienst auf das nächste Level!"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1, viewport-fit=cover"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Jetzt Partner werden! 🤝 FoodAlley.de 🏆"
        />
        <meta
          property="og:description"
          content="Werden Sie jetzt Partner von FoodAlley.de und sparen Sie sich überhöhte Provisionen. Bringen Sie Ihren Lieferdienst auf das nächste Level!"
        />
        <meta property="og:image" content="/sales-beratung.jpg" />
        <meta property="og:image:alt" content="website image" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content="Jetzt Partner werden! 🤝 FoodAlley.de 🏆"
        />
        <meta
          property="twitter:description"
          content="Werden Sie jetzt Partner von FoodAlley.de und sparen Sie sich überhöhte Provisionen. Bringen Sie Ihren Lieferdienst auf das nächste Level!"
        />
        <meta property="twitter:image" content="/sales-beratung.jpg" />
        <meta property="twitter:image:alt" content="website image" />
      </Head>

      <header>
        <div className="fixed md:h-[60px] h-[55px] w-full bg-white shadow-sm z-50 top-0 left-0">
          <div className="container mx-auto h-full">
            <div className="grid grid-cols-1 h-full">
              <div className="flex items-center">
                <Link href="/" passHref className="flex">

                  <Image
                    src="/Logo-FoodAlley-INLINE.svg"
                    alt="FoodAlley Logo"
                    width={112}
                    height={29}
                    style={{
                      maxWidth: "100%",
                      height: "auto"
                    }} />

                </Link>
              </div>
            </div>
          </div>
        </div>

        <HeroElement>
          <div className="lg:h-[400px] md:h-[300px] sm:h-[200px] h-[110px] w-full mt-[55px] md:mt-[60px]">
            <div className="relative w-full h-full">
              <Image priority src={HeroImage} alt="FoodAlley Partner" fill sizes="100vw" />
            </div>
          </div>
        </HeroElement>
      </header>

      <main>
        <div className="container mx-auto py-[25px]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div>
              {city && restaurantName ? (
                <>
                  <h1>
                    FoodAlley kommt nach{" "}
                    <span className="text-fa-green">{city}</span>!
                  </h1>
                  <h2 className="mt-[15px] md:mt-[35px] mb-[10px] md:mb-[20px]">
                    Hallo {restaurantName},
                  </h2>
                </>
              ) : (
                <>
                  <h1>
                    Jetzt <span className="text-fa-green">anfragen</span> und
                    Partner werden
                  </h1>
                  <h2 className="mt-[15px] md:mt-[35px] mb-[10px] md:mb-[20px]">
                    FoodAlley kommt auch in Ihre Stadt
                  </h2>
                </>
              )}

              <p>
                Werden Sie jetzt Partner bei FoodAlley.de und kommen Sie in den
                Genuss eines Liefersystems, das alle Bereiche Ihrer Gastronomie
                abdeckt und dabei noch für Ihre Kunden ein großartiges Erlebnis
                während der Bestellung bietet.
              </p>
              <p>
                Kunden mögen es einfach und simpel, daher ist unser Prozess beim
                Bestellen genau dafür ausgelegt. Jetzt mehr Bestellungen
                generieren - mit FoodAlley!
              </p>

              <WhyElement className="hidden lg:block mt-[50px]" />
            </div>

            <div>
              <ContactForm
                formRef={formRef}
                initialValues={{
                  name,
                  restaurantName,
                  street,
                  houseNumber,
                  city,
                  zip,
                }}
              />
            </div>
          </div>
        </div>

        <div className="py-[25px] md:py-[50px] bg-[#F9F9F9]">
          <div className="container mx-auto flex justify-center">
            <div className="grid grid-cols-3 gap-2 gap-y-4 md:gap-x-8 gap-x-1 sm:gap-x-2 text-center">
              <div>
                <Image
                  src="/FACircle.svg"
                  width={130}
                  height={40}
                  title="FoodAlley.de"
                  alt="FoodAlley.de"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </div>
              <div></div>
              <div>
                <Image
                  src="/AlternativeCircle.svg"
                  width={130}
                  height={40}
                  title="Alternative"
                  alt="Alternative"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </div>

              <CompareLine left="7,5%" middle="Provision" right="14%" />
              <CompareLine
                left="750 €"
                middle={
                  <>
                    <strong>bei Monatsumsatz</strong>
                    <br />
                    10.000 €
                  </>
                }
                right="1.400 €"
              />
              <CompareLine
                left="9.000 €"
                middle={
                  <>
                    <strong>bei Jahresumsatz</strong>
                    <br /> 120.000 €
                  </>
                }
                right="16.800 €"
              />

              <div>
                <div className="grid grid-cols-2">
                  <div></div>
                  <div className="border-dotted border-l-2 border-[#979797] h-[45px]"></div>
                </div>
                <div className="grid grid-cols-2">
                  <div></div>
                  <div className="border-dotted border-t-2 border-[#979797]"></div>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="w-full bg-fa-green text-white h-[44px] rounded-md tracking-widest mt-[22px]"
                >
                  +7.800 €
                </button>
              </div>
              <div>
                <div className="grid grid-cols-2">
                  <div></div>
                  <div className="border-dotted border-l-2 border-[#979797] h-[45px]"></div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="border-dotted border-t-2 border-[#979797]"></div>
                  <div></div>
                </div>
              </div>

              <div className="col-span-3 font-bold text-[16px] tracking-[0.71px]">
                Ihre mögliche Ersparnis!
                <br />
                <span className="text-fa-green">Jetzt</span> Partner werden!
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <WhyElement className="lg:hidden mt-[35px]" />
        </div>

        <div className="py-[35px] md:py-[75px] bg-white">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
              <div>
                <SalesPitchArgument>
                  <h2>
                    Der Vorteil eines modernen und einfachen Liefersystems
                  </h2>
                  <p>
                    Das Liefersystem von FoodAlley, modern und performant,
                    integriert sich reibungslos in die Abläufe jeder Gastronomie
                    und ist dabei noch benutzerfreundlich und für jedes Endgerät
                    zugeschnitten. Ein zentrales System für deine Kunden und
                    dich - Das Fax gehört der Vergangenheit an.
                  </p>
                  <p>
                    Kunden mögen es einfach und simpel, daher ist der Prozess
                    beim Bestellen genau dafür ausgelegt. Jetzt mehr
                    Bestellungen generieren - mit FoodAlley!
                  </p>
                </SalesPitchArgument>
                <SalesPitchArgument>
                  <h2>Viele Anbieter am Markt, wieso also FoodAlley?</h2>
                  <p>
                    Das Nutzerverhalten entwickelt sich stetig weiter, genau
                    deshalb verbessern und optimieren wir unser Produkt stetig
                    und passen es an den Markt und die Bedürfnisse deiner Kunden
                    und dich an.
                  </p>
                  <p>
                    Dein Erfolg ist unser Erfolg! Nur durch partnerschaftliche
                    und nachhaltige Zusammenarbeit sind wir beide erfolgreich.
                    Wir betreiben für dich kostenlose Werbung auf allen
                    relevanten Kanälen und helfen so zusätzlich deinen Umsatz zu
                    steigern und präsenter am Markt zu sein.
                  </p>
                  <p>
                    Menschen möchten überzeugt werden, genau dafür sind wir da.
                    Wir stellen das Liefersystem und du das Essen!
                  </p>
                </SalesPitchArgument>
                <SalesPitchArgument>
                  <h2>Was ist uns besonders wichtig?</h2>
                  <p>
                    Unser übergeordnetes Ziel ist es, den Gastronomen, welche
                    unser System benutzen auch wirklich zu mehr Umsatz zu
                    verhelfen - und das ohne Risiko. Dank unserem fairen
                    Provisionsmodell bleibt mehr übrig - garantiert!
                  </p>
                </SalesPitchArgument>
              </div>
              <div>
                <SalesImageWrapper>
                  <div className="relative w-full h-[248px] md:h-[535px] shadow-fa rounded-md overflow-hidden mt-[15px] md:mt-0">
                    <div className="relative w-full h-full">
                      <Image src={SalesImage} alt="FoodAlley Partner" fill sizes="100vw" />
                    </div>
                  </div>
                </SalesImageWrapper>
                <div className="mt-4 flex justify-end">
                  <a
                    href="tel:+4915172421702"
                    className="block w-full md:w-[50%] bg-fa-green text-white rounded-md tracking-widest p-3 text-center shadow-fa"
                  >
                    Jetzt anrufen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[25px] md:py-[50px] bg-[#F9F9F9]">
          <div className="container mx-auto">
            <h2 className="text-center text-[18px] md:text-[21px] tracking-[0.8px] md:tracking-[0.93px] mb-[25px] md:mb-[55px]">
              Das sagen unsere Partner über uns
            </h2>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
              <RecommendationBox
                text="Wir bedanken uns vielmals für die gute Partnerschaft. Das System
                      ist wirklich super und die Kunden haben uns auch schon darauf
                      angesprochen. Sehr leicht zu verstehen und dabei noch günstiger als die
                      Anderen auf dem Markt."
                logoSrc="/logo_mandarin.png"
                logoWidth={80}
                logoHeight={80}
                restaurantName="Mandarin Garden Rottweil"
                ownerName="Yucui Zhang"
                url="https://www.mandarin-garden-rottweil.de/"
              />
              <RecommendationBox
                text="Dank FoodAlley haben wir wieder guten Umsatz. Dass die Werbung hier kostenfrei dabei ist, hat uns wirklich geholfen. Danke auch an Marc, der immer für uns erreichbar ist und mir immer hilft, sollte ich Probleme haben."
                logoSrc="/logo_kikoo.png"
                logoWidth={916}
                logoHeight={594}
                restaurantName="Kikoo Asian Restaurant"
                ownerName="Wang Chao"
                url="https://www.kikoo-asian-restaurant.de"
              />
              <RecommendationBox
                text="Super nette Menschen und tolle Zusammenarbeit. Funktioniert einwandfrei und die Kunden sind begeistert. Endlich ein Anbieter der auch einhält, was er verspricht und auch erreichbar ist, sollten wir Hilfe brauchen."
                logoSrc="/logo_engel.png"
                logoWidth={353}
                logoHeight={247}
                restaurantName="Gasthof Engel"
                ownerName="Dalwinder Singh Latha"
                url="https://www.gasthof-engel-owingen.de/"
              />
              <RecommendationBox
                text="Tablet und Drucker funktionieren und integrieren sich perfekt in unseren Ablauf im Restaurant. Wir können alles selbst einstellen und bearbeiten, was toll ist. Danke für die tolle Zusammenarbeit, ich empfehle euch jederzeit."
                logoSrc="/logo_milano.png"
                logoWidth={400}
                logoHeight={185}
                restaurantName="Milano Pizza Service"
                ownerName="Sukhwinder Singh"
                url="https://www.milano-pizza-burgstetten.de"
              />
            </div>

            <div className="text-center">
              <button
                type="button"
                className="p-[12px] bg-fa-green text-white rounded-md tracking-widest mt-[22px] md:mt-[55px] lg:w-[250px] sm:w-[50%] w-full"
                onClick={scrollToForm}
              >
                Jetzt anfragen
              </button>
            </div>

            <div className="md:mt-[150px] mt-[55px]">
              <div className="grid lg:grid-cols-none grid-cols-2 text-center gap-4 justify-evenly lg:flex lg:justify-items-end">
                <div className="col-span-2 lg:col-auto flex justify-center items-center">
                  <div className="relative w-[175px] lg:w-[220px]">
                    <Image
                      src="/schwabo.png"
                      alt="Schwarzwälder Bode"
                      width={2560}
                      height={448}
                      style={{
                        maxWidth: "100%",
                        height: "auto"
                      }} />
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div className="relative w-[97px] lg:w-[136px]">
                    <Image
                      src="/seefunk.png"
                      alt="Radio Seefunk"
                      width={300}
                      height={157}
                      style={{
                        maxWidth: "100%",
                        height: "auto"
                      }} />
                  </div>
                </div>
                <div className="order-last lg:order-none col-span-2 lg:col-auto flex justify-center items-center">
                  <div className="relative w-[144px] lg:w-[174px]">
                    <Image
                      src="/suedkurier.png"
                      alt="Südkurier Medienhaus"
                      width={500}
                      height={150}
                      style={{
                        maxWidth: "100%",
                        height: "auto"
                      }} />
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div className="relative w-[56px] lg:w-[79px]">
                    <Image
                      src="/antenne1.png"
                      alt="Radio Antenne 1 Neckarburg"
                      width={340}
                      height={304}
                      style={{
                        maxWidth: "100%",
                        height: "auto"
                      }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-[#484F51] md:pt-[45px] pt-[35px] pb-[30px] w-full text-white">
        <FooterWrapper>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-4">
              <div className="relative w-[193px] hidden lg:flex items-center">
                <div className="relative">
                  <Link
                    href="https://www.foodalley.de"
                    passHref
                    target="_blank"
                    rel="noopener noreferrer">

                    <Image
                      src="/Logo-FoodAlley-INLINE-WHITE.png"
                      alt="FoodAlley Logo"
                      width={2290}
                      height={592}
                      style={{
                        maxWidth: "100%",
                        height: "auto"
                      }} />

                  </Link>
                </div>
              </div>
              <div>
                <h4 className="text-[17px] tracking-[0.75px] mb-[15px]">
                  FoodAlley
                </h4>
                <ul>
                  <li>
                    <a
                      href="https://my.foodalley.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Partner Login
                    </a>
                  </li>
                  <li>
                    <Link href="/">Partner werden</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.foodalley.de/contact"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kontaktinformationen
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h4>Social Media</h4>
                <ul>
                  <li>
                    <a
                      href="https://blog.foodalley.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/foodalley.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/FoodAlley.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h4>Datenschutz</h4>
                <ul>
                  <li>
                    <a
                      href="https://www.foodalley.de/impress"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Impressum
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.foodalley.de/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzerklärung
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#696969] my-[30px]">&nbsp;</div>

          <div className="container mx-auto">
            <h3 className="mb-[15px]">
              FoodAlley Restaurants - fair Essen bestellen
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-[14px] font-light tracking-[0.62px] gap-2">
              <a
                href="https://www.foodalley.de/stores/78054/Villingen-Schwenningen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Villingen-Schwenningen
              </a>
              <a
                href="https://www.foodalley.de/stores/75172/Pforzheim"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Pforzheim
              </a>
              <a
                href="https://www.foodalley.de/stores/77933/Lahr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Lahr
              </a>
              <a
                href="https://www.foodalley.de/stores/76185/Karlsruhe"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Karlsruhe
              </a>
              <a
                href="https://www.foodalley.de/stores/78628/Rottweil"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Rottweil
              </a>
              <a
                href="https://www.foodalley.de/stores/78224/Singen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Singen
              </a>
              <a
                href="https://www.foodalley.de/stores/72555/Metzingen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Metzingen
              </a>
              <a
                href="https://www.foodalley.de/stores/73037/Goeppingen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Göppingen
              </a>
              <a
                href="https://www.foodalley.de/stores/72760/Reutlingen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Reutlingen
              </a>
              <a
                href="https://www.foodalley.de/stores/78462/Konstanz"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Konstanz
              </a>
              <a
                href="https://www.foodalley.de/stores/71332/Waiblingen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Waiblingen
              </a>
              <a
                href="https://www.foodalley.de/stores/72070/Tuebingen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Tübingen
              </a>
              <a
                href="https://www.foodalley.de/stores/72336/Balingen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Balingen
              </a>
              <a
                href="https://www.foodalley.de/stores/70191/Stuttgart"
                target="_blank"
                rel="noopener noreferrer"
              >
                Restaurants in Stuttgart
              </a>
            </div>
          </div>
        </FooterWrapper>
      </footer>
    </div>
  );
}
