import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useEffect, useState } from "react";
import LoaderTyping from "./LoaderTyping";
import Image from "next/image";
import isEmpty from "lodash/isEmpty";

const StyledForm = styled.form`
  & .formGroup.has-error {
    label {
      color: #ec971f;
    }
    input,
    input:focus {
      border-color: #ec971f;
    }
  }

  & p.error-label {
    display: none;
  }
  & .formGroup.has-error ~ p.error-label {
    color: #ec971f;
    display: block;
  }

  & .potter {
    width: 0;
    height: 0;
    overflow: hidden;
  }
`;

export default function ContactForm(props) {
  // simple Bot busting algo
  const [busted, setBusted] = useState(false);
  const bustedBot = () => setBusted(true);

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sendError, setSendError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: props.initialValues,
  });

  useEffect(() => {
    if (!isEmpty(props.initialValues)) {
      Object.entries(props.initialValues).forEach(([name, value]) =>
        setValue(name, value)
      );
    }
  }, [props.initialValues, setValue]);

  const resetForm = () => {
    reset({
      name: "",
      restaurantName: "",
      city: "",
      street: "",
      houseNumber: "",
      zip: "",
      telephone: "",
      email: "",
    });
    setSendError();
  };
  const onSubmit = (data) => {
    if (busted) {
      console.log("You are a Bot, fuck you!");
    } else {
      setSubmitting(true);
      setSendError();

      fetch("/api/form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.text().then((text) => {
              throw new Error(text);
            });
          }
        })
        .then((data) => {
          setSuccess(true);
          resetForm();
        })
        .catch((error) => {
          setSendError(error.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const ErrorMessage = (props) => {
    return (
      <p className="ml-1 mt-2 text-xs error-label">
        {errors[props.name] &&
          errors[props.name].type === "required" &&
          "Dieses Feld wird benötigt"}
        {errors[props.name] &&
          errors[props.name].type === "pattern" &&
          "Eingabe ungültig"}
        {errors[props.name] &&
          errors[props.name].type === "minLength" &&
          "Eingabe zu kurz"}
        {errors[props.name] &&
          errors[props.name].type === "maxLength" &&
          "Eingabe zu lang"}
      </p>
    );
  };

  const { ref, ...restRegisterName } = register("name", {
    required: true,
    minLength: 5,
    maxLength: 80,
  });

  return (
    <div>
      <h3 className="text-fa-green mb-4">
        Ausfüllen, abschicken und anfragen!
      </h3>

      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        className="relative tracking-wider font-normal text-[16px]"
      >
        {success && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-[#F9F9F9] z-20 rounded-md flex flex-col p-8 items-center justify-center text-center">
            <div className="absolute right-0 top-0 m-[15px]">
              <div
                className="relative hover:cursor-pointer hover:drop-shadow-md w-[15px] h-[15px]"
                onClick={() => setSuccess(false)}
              >
                <Image
                  src="/Xnew.svg"
                  width={15}
                  height={15}
                  title="Zurück"
                  alt="zurück"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </div>
            </div>
            <div className="mb-[55px]">
              <Image
                src="/IconCheckGreenFull.svg"
                alt="Erfolg"
                width={75}
                height={75}
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
            </div>
            <div className="mb-[21px]">
              <h3 className="font-bold text-[18px] tracking-[0.8px] mb-[5px]">
                Anfrage erfolgreich versendet
              </h3>
              <span className="font-light text-[16px] tracking-[0.71px]">
                Absolut richtige Entscheidung
              </span>
            </div>
            <div>
              <span className="font-light text-[14px] tracking-[0.62px]">
                Unser Vertriebsteam wird sich in den nächsten Tagen mit dir in
                Verbindung setzen und dich über den weiteren Ablauf informieren.
                Sei gespannt!
              </span>
            </div>
          </div>
        )}

        <div className="mb-2">
          <div className={`relative formGroup ${errors.name && "has-error"}`}>
            <input
              type="text"
              id="name"
              className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
              placeholder=" "
              ref={(e) => {
                ref(e);
                props.formRef.current = e;
              }}
              {...restRegisterName}
            />
            <label
              htmlFor="name"
              className="absolute duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Ihr Name
            </label>
          </div>

          <ErrorMessage name="name" />
        </div>

        <div className="relative mb-2">
          <div
            className={`relative formGroup ${
              errors.restaurantName && "has-error"
            }`}
          >
            <input
              type="text"
              id="restaurantName"
              className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
              placeholder=" "
              {...register("restaurantName", {
                required: true,
                minLength: 5,
                maxLength: 80,
              })}
            />
            <label
              htmlFor="restaurantName"
              className="absolute  text-black  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Restaurant Name
            </label>
          </div>
          <ErrorMessage name="restaurantName" />
        </div>

        <div className="relative mb-2 grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <div
              className={`relative formGroup ${errors.street && "has-error"}`}
            >
              <input
                type="text"
                id="street"
                className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                {...register("street", {
                  required: true,
                  maxLength: 60,
                  pattern:
                    /^(?=\s*(?:[\w.a-zA-ZäöüÄÖÜß-]\s*){5,}).*[^0-9]+$|^([A-Ua-u]{1}[0-9]{1})$/,
                })}
              />
              <label
                htmlFor="street"
                className="absolute duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Straße
              </label>
            </div>
            <ErrorMessage name="street" />
          </div>

          <div>
            <div
              className={`relative formGroup ${
                errors.houseNumber && "has-error"
              }`}
            >
              <input
                type="text"
                id="houseNumber"
                className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
                placeholder=" "
                {...register("houseNumber", {
                  required: true,
                  maxLength: 8,
                  minLength: 1,
                  pattern: /^(?:[0-9]+[/-]*[0-9]*[\s]*[a-zA-Z]?)+$/,
                })}
              />
              <label
                htmlFor="houseNumber"
                className="absolute  text-black  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Nr.
              </label>
            </div>
            <ErrorMessage name="houseNumber" />
          </div>
        </div>

        <div className="relative mb-2 grid grid-cols-3 gap-2">
          <div>
            <div className={`relative formGroup ${errors.zip && "has-error"}`}>
              <input
                type="text"
                id="zip"
                maxLength={5}
                className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
                placeholder=" "
                {...register("zip", {
                  required: true,
                  maxLength: 5,
                  minLength: 5,
                  pattern: /^[0-9]{1,5}$/,
                })}
              />
              <label
                htmlFor="zip"
                className="absolute  text-black  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                PLZ
              </label>
            </div>
            <ErrorMessage name="zip" />
          </div>
          <div className="col-span-2">
            <div className={`relative formGroup ${errors.city && "has-error"}`}>
              <input
                type="text"
                id="city"
                className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
                placeholder=" "
                {...register("city", {
                  required: true,
                  maxLength: 50,
                  minLength: 2,
                  pattern:
                    /^(?!.*\.\.)(\d*\s*[a-zA-ZäöüÄÖÜß\- \*.]*\ *[^0-9])$/,
                })}
              />
              <label
                htmlFor="city"
                className="absolute  text-black  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Ort
              </label>
            </div>
            <ErrorMessage name="city" />
          </div>
        </div>

        <div className="mb-2">
          <div
            className={`relative formGroup ${errors.telephone && "has-error"}`}
          >
            <input
              type="tel"
              id="telephone"
              className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
              placeholder=" "
              {...register("telephone", {
                required: true,
                maxLength: 50,
                minLength: 8,
                pattern: /^(?:\(\+?\d+\)|\+?\d+)(?:\s*[-/]*\s*\d+)+$/i,
              })}
            />
            <label
              htmlFor="telephone"
              className="absolute  text-black  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Telefonnummer
            </label>
          </div>
          <ErrorMessage name="telephone" />
        </div>

        <div className="mb-2">
          <div className={`relative formGroup ${errors.email && "has-error"}`}>
            <input
              type="email"
              id="email"
              className="block px-2.5 pb-2.5 pt-4 w-full  text-black bg-transparent rounded-lg border border-[#858585]/20 appearance-none focus:outline-none focus:ring-0 focus:border-[#858585]/50 peer"
              placeholder=" "
              {...register("email", {
                required: true,
                maxLength: 50,
                minLength: 2,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
              })}
            />
            <label
              htmlFor="email"
              className="absolute  text-black  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              E-Mail
            </label>
          </div>
          <ErrorMessage name="email" />
        </div>

        <div className="potter">
          <input
            type="checkbox"
            name="contact_me_by_fax_only"
            id="contact_me_by_fax_only"
            value="1"
            tabIndex="-1"
            autoComplete="off"
            onChange={() => bustedBot()}
            onClick={() => bustedBot()}
          />
          <label htmlFor="contact_me_by_fax_only">Nur Fax</label>
        </div>

        <div className="">
          <button
            type="submit"
            className="w-full bg-fa-green text-white h-[50px] rounded-md tracking-widest"
          >
            {submitting && <LoaderTyping style={{ marginRight: "15px" }} />}
            Anfrage absenden
          </button>
        </div>

        {sendError && (
          <div className="flex justify-end">
            <div className="py-3 pl-4 pr-8 mt-4 bg-[#EC971F] text-white rounded-md text-[14px] tracking-[0.8px] flex">
              <div className="flex items-center">
                <div className="mr-[15px] flex-shrink-0 w-[24px]">
                  <Image
                    src="/FailFull.svg"
                    alt="Fehler"
                    width={24}
                    height={24}
                    style={{
                      maxWidth: "100%",
                      height: "auto"
                    }} />
                </div>
              </div>
              <div>
                <p className="font-medium m-0">Fehler bei der Übermittlung</p>
                <span className="font-light">
                  {sendError !== ""
                    ? JSON.stringify(sendError)
                    : "Bitte versuchen Sie es erneut"}
                </span>
              </div>
            </div>
          </div>
        )}
      </StyledForm>
    </div>
  );
}
